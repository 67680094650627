* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

.block-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
}

.text-1 {
  -webkit-line-clamp: 1;
}

.text-2 {
  -webkit-line-clamp: 2;
}

.text-3 {
  -webkit-line-clamp: 3;
}

.hero-slider .slick-prev,
.hero-slider .slick-next {
  display: none !important;
}

.hero-slider .slick-list {
  border-radius: 4px !important;
}

.hero-slider .slick-slide span {
  border-radius: 4px;
}
.slick-prev:before, .slick-next:before {
  content: none !important;
}

.img-slider .slick-slider .slick-list {
  height: 280px !important;
  width: 320px !important;
  margin-bottom: 10px !important;
}

.img-slider .slick-next,
.img-slider .slick-prev {
  display: none !important;
}

.slick-next {
  color: #656565 !important;
}

.slick-prev {
  color: #656565 !important;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: #656565 !important;
}

.slick-dots {
  bottom: -50px !important;
}

.slick-dots li {
  margin: 0px !important;
}

.slick-dots li button:before {
  font-size: 8px !important;
}

.slick-dots li.slick-active button:before {
  color: #820000 !important;
  opacity: 1 !important;
  font-size: 8px !important;
}

.scroll-container {
  overflow-x: auto;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

@media (min-width: 700px) {
  .scroll-container::-webkit-scrollbar {
    height: 8px;
  }

  .scroll-container::-webkit-scrollbar-track {
    background: #ffff;
  }

  .scroll-container::-webkit-scrollbar-thumb {
    background-color: #ebe7e7;
    border-radius: 10px;
  }

  .drawer-scroll::-webkit-scrollbar {
    width: 8px;
  }
}

@media (max-width: 600px) {
  .slick-next,
  .slick-prev {
    opacity: 0 !important;
  }

  .slick-dots {
    opacity: 0 !important;
  }
}

.yarl__root {
  position: absolute !important;
  width: 100% !important;
  z-index: 10 !important;
}

.yarl__slide.yarl__slide_current {
  border-radius: 16px !important;
  cursor: pointer !important;
  object-fit: cover;
}

.yarl__flex_center.yarl__thumbnails_thumbnail.yarl__thumbnails_thumbnail_active {
  border-color: #000 !important;
  border-width: 2px !important;
}


.yarl__thumbnails_bottom,
.yarl__thumbnails_vignette {
  background: unset !important;
}

.yarl__container,
.yarl__thumbnails_container {
  background-color: #ffff !important;
}

.yarl__thumbnails_thumbnail .yarl__slide_image {
  object-fit: contain !important;
}

.yarl__thumbnails_thumbnail {
  background: #ffff !important;
}


@media (max-width: 600px) {
  
  .yarl__thumbnails_thumbnail .yarl__slide_image {
    max-width: 100px !important;
    max-height: 100px !important;
  }
  .yarl__flex_center.yarl__thumbnails_thumbnail {
    width: 60px !important;
    height: 50px !important;
  }
  .yarl__slide.yarl__slide_current .yarl__slide_image{
    width: 200px !important;
  }
}
